export enum SampleInstructionRoutes {
  Any = 'sample-instruction.**',
  Main = 'sample-instruction',
  AnyCtInstructionReport = 'sample-instruction.ct-instruction-report.**',
  AnyManageTemplates = 'sample-instruction.manage-templates.**',
  HomePage = 'sample-instruction.ct-instruction-report',
  SelectTc = 'sample-instruction.ct-instruction-report.select-tc',
  Tc = 'sample-instruction.ct-instruction-report.tc',
  Overview = 'sample-instruction.ct-instruction-report.tc.overview',
  OverviewDomestic = 'sample-instruction.ct-instruction-report.tc.overview.domestic',
  OverviewInternational = 'sample-instruction.ct-instruction-report.tc.overview.international',
  Create = 'sample-instruction.ct-instruction-report.tc.create',
  CreateDomestic = 'sample-instruction.ct-instruction-report.tc.create.domestic',
  CreateInternational = 'sample-instruction.ct-instruction-report.tc.create.international',
  Edit = 'sample-instruction.ct-instruction-report.tc.edit',
  EditDomestic = 'sample-instruction.ct-instruction-report.tc.edit.domestic',
  EditInternational = 'sample-instruction.ct-instruction-report.tc.edit.international',
  ManageTemplates = 'sample-instruction.manage-templates',
  ViewTemplate = 'sample-instruction.manage-templates.view',
  EditTemplate = 'sample-instruction.manage-templates.edit',
}

export interface SampleInstructionLink {
  label: string;
  value: SampleInstructionRoutes;
  values?: string[];
}

export const SIT_LINKS: SampleInstructionLink[] = [
  {
    label: 'SIT.INSTRUCTION_REPORT.MENU_NAME',
    value: SampleInstructionRoutes.HomePage,
    values: [
      SampleInstructionRoutes.SelectTc,
      SampleInstructionRoutes.Overview,
      SampleInstructionRoutes.Create,
      SampleInstructionRoutes.Edit,
      SampleInstructionRoutes.OverviewDomestic,
      SampleInstructionRoutes.OverviewInternational,
      SampleInstructionRoutes.CreateDomestic,
      SampleInstructionRoutes.CreateInternational,
      SampleInstructionRoutes.EditDomestic,
      SampleInstructionRoutes.EditInternational,
    ],
  },
  {
    label: 'SIT.MANAGE_TEMPLATES.MENU_NAME',
    value: SampleInstructionRoutes.ViewTemplate,
    values: [
      SampleInstructionRoutes.ManageTemplates,
      SampleInstructionRoutes.ViewTemplate,
      SampleInstructionRoutes.EditTemplate,
    ],
  },
];
